<template>
  <div style="text-align: center">
    <div class="seltxt" style="color: green">{{fio}}</div>
    <div v-html="txt"></div>
  </div>
</template>

<script>
import axios from 'axios'
import { hostus } from "../utils/func.js";

export default {
  mounted: async function() {
    let res = await axios.get(`${hostus}/mysqlus/proto`, {params: {file: `inforeg-1` }})
    let proto = res.data
    if (proto.errmsg) this.txt = " Не найден файл" 
    else this.txt = proto
    //console.log(this.$store.getters['userFIO'])
  },
  data() {
    return {
      txt: '',
      user: Object.assign({}, this.$store.getters['user_da'])
      //fio: this.$store.getters['userFIO']
    }
  },
  computed: {
    fio () { return `${this.user.name_f} ${this.user.name_i} ${this.user.name_o}` }
  },
  methods: {
  },
}
</script>